<template>
  <div class="log-table">
		<h4>{{title}}</h4>
    <div class="table-row header-row">
			<div class="cell header-cell" v-for="(col, index) in columns" v-bind:key="index">{{column_names[index]}}</div>
			<div class="cell header-cell">
				<div v-if="trendColumns">Trend</div>
			</div>
    </div>
    <div
      class="table-row"
      v-for="(log, index) in logs"
      v-bind:key="index"
      :class="index % 2 == 1 ? 'alternate' : ''"
    >
			<div class="cell" v-for="(col, index) in columns" v-bind:key="index">{{ log[col] ?? "" }}</div>
			<div class="cell">
				<div v-if="trendColumns">
					<i :class="getTrendColumnValue(log)" :style="getTrendColumnColor(log)"></i>
				</div>
			</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BasicLogTable",
  props: {
		title: {
			type: String,
			required: true
		},
    logs: {
      type: Array,
      required: true,
    },
		columns: {
			type: Array,
			required: true
		},
		column_names: {
			type: Array,
			required: true
		},
		trendColumns: {
			type: Array,
			required: false
		}
  },
  setup(props) {
    return {
			getTrendColumnValue: (log) => {
				if (log[props.trendColumns[0]] > log[props.trendColumns[1]] * 1.2)
					return "fas fa-arrow-down"
				else if (log[props.trendColumns[0]] * 1.2 < log[props.trendColumns[1]]) {
					return "fas fa-arrow-up"
				}
				return "fas fa-minus"
			},
			getTrendColumnColor: (log) => {
				if (log[props.trendColumns[0]] > log[props.trendColumns[1]] * 1.2)
					return "color: " + "#278c0b;"
				else if (log[props.trendColumns[0]] * 1.2 < log[props.trendColumns[1]]) {
					return "color: " + "#a10023;"
				}
				return "color: " + "#000000;"
			}
		};
  },
};
</script>

<style scoped>
.log-table {
  margin: 15px;
}

.header-row {
  font-weight: bold;
  border-bottom: 1px solid gray;
}

.alternate {
  background-color: lightgrey;
}

.table-row {
  padding: 5px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 6fr 1fr 1fr 1fr 1fr 1fr 1fr;
  text-align: start;
  align-items: center;
}

.header-cell {
    max-height: 200px !important;
}

.cell {
  font-size: 14px;
  max-height: 25px;
  padding: 0px 10px;
  align-items: center;
  justify-content: start;
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: start;
  width: 100%;
}


</style>