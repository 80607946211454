<template>
  <div class="main-container">
    <h2>{{ "Rumble Rivals Daily Technical Report - " + dateText }}</h2>
    <div class="btn-section">
      <Button
        :loading="isLoading"
        text="Apply"
        type="info"
        :onClick="() => getLogsReport()"
      />
    </div>
    <div class="filter-container">
      <div class="input-container">
        <p>Log Date</p>
        <DatePicker
          v-model="selectedDate"
          mode="date"
          :model-config="{
            type: 'number',
            mask: 'DD/MM/YYYY',
          }"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="input"
              id="creation_date"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </DatePicker>
      </div>
      <div class="input-container">
        <p>Version</p>
        <input class="input" v-model="version" placeholder="All Versions" />
      </div>
    </div>
    <p v-if="dateText === moment().format('(MMMM Do YYYY)')">
      Warn: Results are incomplete for today and trend comparison may me misleading
    </p>
    <div class="table-section">
      <BasicLogTable
        title="Module Based Error Logs"
        :logs="moduleLogs"
        :columns="['source', 'user_cnt', 'avg', 'count', 'weekly_per_ccu', 'count_per_ccu']"
        :column_names="['Source', 'User Count', 'Avg (7days)', 'Count', 'Avg (7 days) Per Ccu', 'Count Per Ccu']"
        :trendColumns="['weekly_per_ccu', 'count_per_ccu']"
      />
      <BasicLogTable
        title="User Based Error Logs"
        :logs="userLogs"
        :columns="['source', '', '', 'count']"
        :column_names="['Source', '', '', 'Count']"
      />
    </div>
    <div class="table-section">
      <BasicLogTable
        title="In App Purchase Issues By User Id"
        :logs="purchaseLogs.length === 0 ? [{ source: 'No purchase related issues' }] : purchaseLogs"
        :columns="['source', '', '', 'count']"
        :column_names="['Source', '', '', 'Count']"
      />
    </div>
    <div class="table-section">
      <div class="chart-container-inner">
        <iframe
          src="https://app.datadoghq.com/graph/embed?token=3cdc017cd24cee5da06e6b5785b37c331fee29454ec56fb62cd4731ace793cd2&height=400&width=800&legend=true"
          width="800"
          height="400"
          frameborder="0"
        ></iframe>
      </div>
      <div class="chart-container-inner">
        <iframe
          src="https://app.datadoghq.com/graph/embed?token=ea9b787614597333b19c24f14bf9d97be3e23a3eabbc814f8277b3c2402ce00a&height=400&width=800&legend=true"
          width="800"
          height="400"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import BasicLogTable from "./components/BasicLogTable.vue";
import moment from "moment";
import "v-calendar/dist/style.css";
import Button from "../../components/common/Button.vue";

export default {
  name: "LogReport",
  components: {
    BasicLogTable,
    Button,
  },
  setup() {
    const store = useStore();

    const isLoading = ref(true);
    const moduleLogs = ref([]);
    const userLogs = ref([]);
    const purchaseLogs = ref([])

    const dateText = ref("");
    const selectedDate = ref(
      moment()
        .utc()
        .subtract(0, "days")
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate()
    );

    const version = ref("");
    let dailyCcu = 1;
    let weeklyCcu = 1;
    const loadingState = reactive({
      isWeeklyChartLoaded: false,
      isDailyChartLoaded: false,
      isLogsLoaded: false,
    });

    onMounted(() => {
      store.dispatch("loader/loadingStatus", false);
      dateText.value = moment(selectedDate.value).format("(MMMM Do YYYY)");
      setTimeout(() => {
        getCcuData();
        getLogsReport();
      }, 2000);
    });

    const getLogsReport = () => {
      isLoading.value = true;
      axios
        .post("/api/monitor/logs-report", { date: selectedDate.value, version: version.value === "" ? undefined : version.value })
        .then((result) => {
          loadingState.isLogsLoaded = true;
          if (result.status === 200) {
            moduleLogs.value = result.data.module_logs
              .map((x) => {
                return {
                  count: x.count,
                  user_cnt: x.user_cnt,
                  source:
                    x.source.length > 100
                      ? x.source.substring(0, 100)
                      : x.source,
                  avg: x.avg,
                  weekly_per_ccu: (x.avg/weeklyCcu).toFixed(2), 
                  count_per_ccu: (x.count/dailyCcu).toFixed(2), 
                };
              })
              .slice(0, 20);
            moduleLogs.value.push({
              source: "RR Certificate Issue",
              user_cnt: result.data.certificate_result[0].cnt,
              count: result.data.certificate_result[0].cnt,
              avg: result.data.average_certificate_result[0].avg,
              weekly_per_ccu: (result.data.average_certificate_result[0].avg/weeklyCcu).toFixed(2), 
              count_per_ccu: (result.data.certificate_result[0].cnt/dailyCcu).toFixed(2),
            });
            userLogs.value = result.data.user_logs.slice(0, 20);
            purchaseLogs.value = result.data.purchase_issues || []

            dateText.value = moment(selectedDate.value).format(
              "(MMMM Do YYYY)"
            );
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const getCcuData = () => {
      let reportStartDate = moment()
        .subtract(1, "days")
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate();
      const reportEndDate = moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate();

      axios
        .post("/api/monitor/ccu-chart", {
          max_date: Math.floor(reportEndDate / 1000),
          min_date: Math.floor(reportStartDate / 1000),
          granularity: 5,
        })
        .then((resp) => {
          loadingState.isDailyChartLoaded = true;
          if (resp.status === 200) {
            if(resp.data.avgCcu)
              dailyCcu = resp.data.avgCcu;
          }
        });

      reportStartDate = moment()
        .subtract(7, "days")
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate();

      axios
        .post("/api/monitor/ccu-chart", {
          max_date: Math.floor(reportEndDate / 1000),
          min_date: Math.floor(reportStartDate / 1000),
          granularity: 60,
        })
        .then((resp) => {
          loadingState.isWeeklyChartLoaded = true;
          if (resp.status === 200) {
            if(resp.data.avgCcu)
              weeklyCcu = resp.data.avgCcu;
          }
        });
    };

    return {
      moduleLogs,
      userLogs,
      loadingState,
      dateText,
      selectedDate,
      version,
      isLoading,
      getLogsReport,
      moment,
      purchaseLogs
    };
  },
};
</script>

<style scoped>
.btn-section {
  position: fixed;
  top: 60px;
  right: 20px;
}

.filter-container {
  display: flex;
  width: 95%;
  margin: 10px;
  border: 1px solid grey;
  padding: 10px;
}

.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin-bottom: 50px;
}

.chart-container-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 95%;
  flex-direction: column;
}

.input-container {
  margin-bottom: 20px;
  margin-right: 20px;
}

.input {
  width: 200px;
  height: 25px;
  border: 1px solid lightgray;
  border-radius: 5px;
}
</style>